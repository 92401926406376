import { BankingPartnerEnum } from '@wizbii/utils/models';

export const PARTNER_ID_LABEL_MAPPING: Record<string, string> = {
  [BankingPartnerEnum.Swan]: 'Swan',
  [BankingPartnerEnum.BforBank]: 'BforBank',
  [BankingPartnerEnum.Boursorama]: 'BoursoBank',
  [BankingPartnerEnum.SocieteGenerale]: 'Société Générale',
  [BankingPartnerEnum.Monabanq]: 'Monabanq',
  [BankingPartnerEnum.Hype]: 'Hype',
  [BankingPartnerEnum.RevolutFr]: 'Revolut FR',
  [BankingPartnerEnum.RevolutIt]: 'Revolut IT',
  [BankingPartnerEnum.Helios]: 'helios',
  [BankingPartnerEnum.TradeRepublic]: 'Trade Republic',
  [BankingPartnerEnum.CreditAgricoleIt]: 'Crédit Agricole IT',
};

export const PartnerListSorted = {
  fr: [
    BankingPartnerEnum.BforBank,
    BankingPartnerEnum.Monabanq,
    BankingPartnerEnum.Helios,
    BankingPartnerEnum.SocieteGenerale,
    BankingPartnerEnum.RevolutFr,
    BankingPartnerEnum.TradeRepublic,
  ],
  it: [BankingPartnerEnum.Hype, BankingPartnerEnum.RevolutIt, BankingPartnerEnum.CreditAgricoleIt],
};
